import { gql } from '@apollo/client';

import { cartPartial } from '../partials';

export const applyCoupon = (extraData: string = '') => gql`
mutation ApplyCouponMutation($code: String!) {
  applyCoupon(input: {code: $code}) {
    ${cartPartial(extraData)}
  }
}`;
