import Head from 'next/head';

import { config } from '@/config';

// TODO: Añadir tipos
const HeaderFromSeo = ({ seo }: any) => (
  <Head>
    <title>{seo?.title}</title>
    <meta name="description" content={seo?.metaDesc} />
    <meta
      name="facebook-domain-verification"
      content="x288t6clob8heomxhz47lumj2wj7lf"
    />
    <meta
      name="robots"
      content={`${seo?.metaRobotsNoindex},${seo?.metaRobotsNofollow}`}
    />
    {config.apps?.ios && (
      <meta
        name="apple-itunes-app"
        content={`app-id=${config.apps.ios.id}, app-argument=${config.apps.ios.url}`}
      />
    )}
    {seo?.canonical && (
      <link rel="canonical" href={seo.canonical.replace('//admin.', '//')} />
    )}

    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/manifest.json" />
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
    <meta name="msapplication-TileColor" content="#d8d0c3" />
    <meta name="theme-color" content="#d8d0c3" />
  </Head>
);

export default HeaderFromSeo;
