import { forwardRef } from 'react';
import { oneLine } from 'common-tags';

import { Spinner } from '@/icons';

import '@/lib/utils/forwardRef';

import type { PolymorphicRef } from '@/types';
import type { ElementType } from 'react';
import type { ButtonProps } from './types';

/**
 * Primary UI component for user interaction
 */
const ButtonBase = <T extends ElementType = 'button'>(
  {
    className = '',
    as,
    variant = 'primary',
    disabled = false,
    size = 'normal',
    negative = false,
    loading = false,
    children,
    testid,
    ...props
  }: ButtonProps<T>,
  ref: PolymorphicRef<T>,
) => {
  const HTMLTag = as || 'button';

  return (
    <HTMLTag
      // prettier-ignore
      className={oneLine`
        u-actions u-actions--link flex max-w-max cursor-pointer items-center justify-center gap-x-2.5 text-center
        ${
          size === 'small' || size === 'small-full'
            ? `u-actions--chips h-7 gap-x-1 rounded-[50px] px-3 py-2`
            : ''
        }
        ${
          size === 'normal' || size === 'normal-full'
            ? `h-8 rounded-2xl px-4 py-2 md:px-6`
            : ''
        }
        ${
          size === 'large' || size === 'large-full'
            ? `h-8 rounded-2xl px-4 py-2 md:h-11 md:rounded-[60px] md:px-10 md:py-4`
            : ''
        }
        ${
          size === 'small-full' || size === 'large-full' || size === 'normal-full'
            ? 'w-full max-w-none'
            : ''
        }
        ${
          variant === 'primary'
            ? `
                ${
                  negative
                    ? 'bg-bg-secondary text-primary-900 hover:bg-primary-400'
                    : 'bg-primary-900 text-typo-alternative hover:bg-primary-600'
                }
                ${disabled ? 'text-primary-50' : ''}
              `
            : ''
        }
        ${
          variant === 'secondary'
            ? `
                border border-solid
                ${
                  negative
                    ? 'border-bg-secondary text-bg-secondary hover:border-secondary-500 hover:text-secondary-500'
                    : 'border-primary-900 text-primary-900 hover:border-primary-600 hover:text-primary-600'
                }
              `
            : ''
        }
        ${
          variant === 'tertiary'
            ? 'bg-secondary-500 text-primary-900 hover:bg-secondary-600'
            : ''
        }
        ${
          variant === 'canceled' && !negative
            ? 'border border-status-error bg-white text-status-error'
            : ''
        }
        ${disabled ? 'pointer-events-none opacity-20' : ''}
        ${className}
      `}
      disabled={disabled}
      ref={ref}
      {...(testid && { 'data-playwright': `btn_${testid}` })}
      {...props}
    >
      {loading ? (
        <Spinner className="animate-spin motion-reduce:hidden" />
      ) : (
        children
      )}
    </HTMLTag>
  );
};
export const Button = forwardRef(ButtonBase);
