import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Spinner
 */
export const Spinner = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.571 20.623A9.334 9.334 0 1 1 12 2.667a1 1 0 1 1 0 2A7.333 7.333 0 1 0 19.333 12a1 1 0 1 1 2 0 9.334 9.334 0 0 1-5.762 8.623Z"
      fill={color}
    />
  </svg>
);
