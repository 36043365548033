import { imagePartial } from '@/lib/graphql/partials/common/ImagePartial';

export const BasicProductPartial = `
  id
  type
  databaseId
  image ${imagePartial}
  sku
  name
  description
  link
  customTemplate
  shippingClasses {
    edges {
      node {
        id
        description
        name
        slug
      }
    }
  }
  sellerInfo {
    name
  }
`;
