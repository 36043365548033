import { config } from '@/config';
import { imagePartial } from '../common';
import { CartProducts } from '../product/CartProducts';

export const cartContents = `
  contents(first: ${config.maxProducts}) {
    itemCount
    edges {
      node {
        extraData(keysIn: ["etiqueta", "etiquetalabel", "etiquetaimage", "etiquetacheckoutimage", "woosb_parent_key", "woosb_variant_quantity", "woobs_variant_discount_type", "woobs_variant_discount_amount", "woobs_variant_discount_percentage"]) {
          value
          key
        }
        key
        quantity
        subtotal
        subtotalTax
        tax
        total
        variation {
          attributes {
            name
            value
            label
          }
          node {
            sellerInfo {
              slug
            }
            databaseId
            price
            regularPrice
            salePrice
            name
            sku
            sellerInfo {
              name
            }
            featuredImage {
              node ${imagePartial}
            }
          }
        }
        product {
          node {
            ${CartProducts}
          }
        }
      }
    }
  }
`;
