import { gql } from '@apollo/client';

import { cartPartial } from '../partials/cart/Cart';

export const cleanCart = gql`
  mutation emptyCart {
    emptyCart(input: { clearPersistentCart: true }) {
      ${cartPartial()}
    }
  }
`;
