import { debug } from '@/lib/utils';

export const AFFILIATION_NAME_OPERATOR = 'Hijos de Rivera';

export const debugGtm = (debugMsg: string, data: any): void => {
  const isProd: boolean = process.env.NODE_ENV === 'production';

  // eslint-disable-next-line no-console
  if (!isProd) console.log(`${debugMsg}: `, data);
};

export const gtmPush = (data: any): void => {
  // Reset ecommerce object
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.dataLayer?.push({
    ecommerce: null,
    connectif: null,
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.dataLayer?.push(data);

  debugGtm('GTM PUSH', data);
};

export const getProductCategoriesTree = (product: any) => {
  let categoiesTree: {
    [key: string]: string;
  } = {};

  product.productCategories.edges.forEach((el: any, index: any) => {
    const categoryLabel =
      index === 0 ? 'item_category' : `item_category${index + 1}`;

    categoiesTree = {
      ...categoiesTree,
      [categoryLabel]: el.node.name,
    };
  });

  return categoiesTree;
};

export const updateCartEvent = (
  loadedCartCount: number,
  responseCartCount: number,
): string =>
  loadedCartCount < responseCartCount ? 'add_to_cart' : 'remove_from_cart';

export const getAddedOrUpdatedItemFromCart = (
  productKey: any,
  cartContent: any,
) => {
  const cartItems = cartContent.contents.products;
  const itemIndex = cartItems.findIndex((el: any) => el.key === productKey);

  return { data: cartItems[itemIndex], itemIndex };
};

export const customerHasBought = async (
  customerEmail: string,
): Promise<any> => {
  let hasBought = null;

  await fetch(`${process.env.NEXT_PUBLIC_FRONT_URL}/api/customerhasbought`, {
    method: 'POST',
    body: JSON.stringify({ email: customerEmail }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      hasBought = res.message ? true : false;
    })
    .catch((err) => debug(err));

  return hasBought;
};

export async function sha256(message: string) {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array

  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
}
