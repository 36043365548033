import { gql } from '@apollo/client';

import { cartPartial } from '../partials';

export const repeatOrder = gql`
  mutation adding($items: [CartItemInput]) {
    fillCart(input: { items: $items }) {
      ${cartPartial()}
      cartErrors {
        reasons
      }
    }
  }
`;
