export const subscriptionPartial = `
  subscriptionData {
    discount_cart
    recurring_purchase
    single_purchase
    subscription
    total
    total_discount
    hasSuscription
    discount
  }
`;
