export const imagePartial = `
  {
    sourceUrl
    altText
    mediaDetails {
      width
      height
    }
  }
`;
