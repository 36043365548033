import { gql } from '@apollo/client';

import { cartPartial, subscriptionPartial } from '../partials';

export const updateSubscriptionSchema = gql`
mutation UpdateSubscriptionSchema($schema: String) {
  updateSubscriptionSchema(input: { schema: $schema }) {
    ${cartPartial(subscriptionPartial)}
  }
}
`;
