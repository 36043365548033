import Image from 'next/future/image';
import { useRouter } from 'next/router';
import OppsPage640 from 'public/assets/images/opps-page/opps_page_640x1136.jpg';
import OppsPage768 from 'public/assets/images/opps-page/opps_page_768x1024.jpg';
import OppsPage1140 from 'public/assets/images/opps-page/opps_page_1440x900.jpg';

import { NextLink } from '@/atoms';
import { Button } from '@/molecules';

import type { TypeErrorProps } from './types';

export function Error({ error }: TypeErrorProps) {
  const router = useRouter();

  return (
    <>
      <div className="absolute inset-x-4 top-10 z-10 flex flex-col gap-6 min-[1140px]:left-1/2 min-[1140px]:top-1/2 min-[1140px]:max-w-[600px] min-[1140px]:-translate-x-full min-[1140px]:-translate-y-1/2 min-[1140px]:gap-12">
        <div className="space-y-2">
          <h1 className="u-display u-display--l text-typo-alternative">
            Esta página se ha ido a tomar el <span>aperitivo</span>
          </h1>
          <p className="u-body u-body--s font-normal text-typo-alternative md:u-body--m">
            Lo sentimos, no hemos podido encontrar la página que buscas. Error{' '}
            {error}.
          </p>
        </div>

        <div className="space-y-2">
          <p className="u-body u-body--m font-normal text-typo-alternative md:u-body--l">
            Vuelve a alguna de nuestras categorías:
          </p>

          <div className="flex flex-col gap-2 overflow-x-auto py-2">
            <div className="flex flex-row gap-2">
              <NextLink
                href="https://bigcrafters.com/marcas/estrella-galicia"
                passHref
              >
                <Button
                  className="!bg-bg-secondary text-typo-primary hover:bg-gradient-to-t hover:from-[#0000004D] hover:to-[#0000004D]"
                  as="a"
                >
                  Estrella Galicia
                </Button>
              </NextLink>
              <NextLink
                href="https://bigcrafters.com/marcas/cervezas-1906"
                passHref
              >
                <Button
                  className="!bg-bg-secondary text-typo-primary hover:bg-gradient-to-t hover:from-[#0000004D] hover:to-[#0000004D]"
                  as="a"
                >
                  1906
                </Button>
              </NextLink>
              <NextLink
                href="https://bigcrafters.com/seleccion-mejores-vinos"
                passHref
              >
                <Button
                  className="!bg-bg-secondary text-typo-primary hover:bg-gradient-to-t hover:from-[#0000004D] hover:to-[#0000004D]"
                  as="a"
                >
                  Vinos
                </Button>
              </NextLink>
            </div>

            <div className="flex flex-row gap-2">
              <NextLink href="https://bigcrafters.com/novedades" passHref>
                <Button
                  className="!bg-bg-secondary text-typo-primary hover:bg-gradient-to-t hover:from-[#0000004D] hover:to-[#0000004D]"
                  as="a"
                >
                  Novedades
                </Button>
              </NextLink>
              <NextLink href="https://bigcrafters.com/alimentacion" passHref>
                <Button
                  className="!bg-bg-secondary text-typo-primary hover:bg-gradient-to-t hover:from-[#0000004D] hover:to-[#0000004D]"
                  as="a"
                >
                  Alimentación
                </Button>
              </NextLink>
              <NextLink
                href="https://bigcrafters.com/ofertas-en-cervezas"
                passHref
              >
                <Button
                  className="!bg-bg-secondary text-typo-primary hover:bg-gradient-to-t hover:from-[#0000004D] hover:to-[#0000004D]"
                  as="a"
                >
                  Promos
                </Button>
              </NextLink>
            </div>
          </div>
        </div>

        {typeof error === 'number' && error === 404 ? (
          <NextLink href="/">
            <a className="u-body u-body--m w-fit text-left font-normal normal-case text-typo-alternative underline md:u-body--l">
              Recargar página
            </a>
          </NextLink>
        ) : (
          <button
            onPointerDown={() => router.reload()}
            className="u-body u-body--m w-fit text-left font-normal normal-case text-typo-alternative underline md:u-body--l"
          >
            Recargar página
          </button>
        )}
      </div>
      <picture>
        <source
          srcSet={OppsPage1140.src}
          media="(min-width: 1140px)"
          className="object-cover object-center"
          draggable={false}
        />
        <source
          srcSet={OppsPage768.src}
          media="(min-width: 768px)"
          className="object-cover object-center"
          draggable={false}
        />
        <source
          srcSet={OppsPage640.src}
          media="(min-width: 640px)"
          className="object-cover object-center"
          draggable={false}
        />
        <Image
          src={OppsPage640}
          alt="Opps background"
          priority
          draggable={false}
          fill
          className="object-cover object-center"
        />
      </picture>
    </>
  );
}
