import type { HTMLAttributes } from 'react';

export const defaultProps = {
  width: '20px',
  height: '20px',
  color: 'currentColor',
};

type Props = {
  /**
   * Permite añadir clases al icono
   */
  className?: string;
  /**
   * Define la anchura del icono
   */
  width?: number | string;
  /**
   * Define la altura del icono
   */
  height?: number | string;
  /**
   * Define el color del icono
   */
  color?: string;
};

type NativeAttrs = Omit<HTMLAttributes<SVGElement>, keyof Props>;

export type IconType = Props & NativeAttrs;
