import type { ISitemapField } from 'next-sitemap';

type TypeExtraConfig = {
  changefreq: ISitemapField['changefreq'];
  priority: ISitemapField['priority'];
};

type TypeConfig = {
  forms: {
    [key: string]: number;
  };
  maxProducts: number;
  maxProductsSelectProductPage: number;
  weightMaxCart: number;
  sitemap: {
    extraConfig: TypeExtraConfig;
    items: string[];
  };
  session: {
    woocommerce: 'woo-session';
  };
  idPageEmployees: number;
  apps: {
    ios?: {
      id: number;
      url: string;
    };
  };
};

export const config: TypeConfig = {
  forms: {
    christmas: 809739,
    joinBigcrafters: 3795,
    acelerateCraftsmen: 800536,
    contact: 3222,
    getOldOrders: 828594,
    modifySubscription: 2019,
    cancelOrder: 1932,
    requestInvoice:
      process.env.NEXT_PUBLIC_BACK_URL === 'https://admin.bigcrafters.com'
        ? 1068008 // PROD FORM ID
        : 987297, // PRE FORM ID
    companyGifts: 1073067,
  },
  maxProducts: 50,
  maxProductsSelectProductPage: 7,
  weightMaxCart: 85,
  sitemap: {
    extraConfig: {
      changefreq: 'daily',
      priority: 0.7,
    },
    items: [
      'brands',
      'categories',
      'categories-attribute',
      'pages',
      'products',
      'sellers',
      'giveaways',
    ],
  },
  session: {
    woocommerce: 'woo-session',
  },
  idPageEmployees: 1059337,
  apps: {
    ios: {
      id: 6449741667,
      url: 'https://apps.apple.com/us/app/bigcrafters/id6449741667',
    },
  },
};
