import { BaseCommonTypeProduct } from './BasicCommonTypeProduct';

export const PurchableBaseProducts = `
  ... on WoosbProduct {
    ${BaseCommonTypeProduct}
    stockStatus
    stockQuantity
    autoCalculatePrice
    woosbOptionalProducts
    woosbIds {
      id
      quantity
    }
    bundledItems {
      databaseId
      type
      ... on VariableProduct {
        variations {
          edges {
            node {
              databaseId
            }
          }
        }
      }
    }
    caracteriSticasDelProducto {
      opciones {
       customDateDelivery
      }
    }
  }
  ... on SimpleProduct {
    ${BaseCommonTypeProduct}
    stockStatus
    stockQuantity
    caracteriSticasDelProducto {
      opciones {
       customDateDelivery
      }
    }
  }
  ... on VariableProduct {
    ${BaseCommonTypeProduct}
    stockStatus
    stockQuantity
    caracteriSticasDelProducto {
      opciones {
       customDateDelivery
      }
    }
  }
`;
