import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import type { ReactNode } from 'react';

interface ModalV2 {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
  children: ReactNode;
  unmount?: boolean;
  backdrop?: {
    color: string;
    opacity: number;
  };
  closeOnBackdropClick?: boolean;
  /**
   * Set the max width of the modal, works with exact pixels or with tailwind classes
   */
  maxWidth?: string | number;
}

// TODO: Fix leave animation not playing.
export const ModalV2 = ({
  open,
  setOpen,
  onClose,
  unmount = false,
  children,
  backdrop = { color: '#6b7280', opacity: 0.75 },
  closeOnBackdropClick = true,
  maxWidth,
}: ModalV2) => {
  function handleClose(open: boolean) {
    if (!closeOnBackdropClick) return;

    onClose && onClose();
    setOpen(open);
  }

  return (
    <Transition appear show={open} unmount={unmount} className="hidden">
      <Dialog
        as="div"
        className="relative z-50"
        open={open}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            style={{
              backgroundColor: backdrop.color,
              opacity:
                backdrop.opacity > 1
                  ? backdrop.opacity / 100
                  : backdrop.opacity,
            }}
            className="fixed inset-0"
            aria-hidden="true"
          />
        </Transition.Child>

        <div className="fixed inset-0 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                {...(maxWidth &&
                  typeof maxWidth === 'number' && {
                    style: { maxWidth: maxWidth },
                  })}
                className={`w-full ${maxWidth && typeof maxWidth === 'string' ? maxWidth : ''}`}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
