import { gql } from '@apollo/client';

import { cartPartial } from '../partials/cart/Cart';

export const updateItemQuantitiesMutation = (extraData: string = '') => gql`
  mutation updateItemQuantities($key: ID!, $quantity: Int!) {
    updateItemQuantities(input: {items: {key: $key, quantity: $quantity}}) {
      clientMutationId
      ${cartPartial(extraData)}
    }
  }
`;
