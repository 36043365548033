import Router from 'next/router';

import {
  getDiscountAmount,
  getRealURL,
  isOperator,
  priceToNumber,
} from '@/lib/utils';
import { AFFILIATION_NAME_OPERATOR, getProductCategoriesTree } from './helpers';

import type { TypeCartOffer } from '@/lib/graphql/types';
import type { TypeGTMProduct } from '@/types';

export const normalizeEventItem = (
  product: any,
  index: number | undefined,
  qty: number,
  variation: any = null,
  bundle?: TypeCartOffer[],
): TypeGTMProduct[] => {
  let regularPrice = product.regularPrice;
  let salePrice = product.salePrice;
  let seller = AFFILIATION_NAME_OPERATOR;
  if (variation) {
    regularPrice = variation.node.regularPrice;
    salePrice = variation.node.salePrice;
    seller = variation.node.sellerInfo?.name;
    if (isOperator(seller)) {
      seller = AFFILIATION_NAME_OPERATOR;
    }
  }

  let discount;
  if (product.onSale) {
    discount = Number.parseFloat(
      getDiscountAmount(
        priceToNumber(regularPrice),
        priceToNumber(salePrice),
      ).toFixed(2),
    );
  }

  let item: TypeGTMProduct = {
    item_id: product.sku,
    item_name: product.name,
    // TODO: cuando se implmente el buy box esto no servirá porque puede haber varios sellers
    affiliation: seller,
    coupon: null,
    currency: 'EUR',
    discount: discount ? discount * qty : null,
    index: index ?? null,
    item_brand: product.brand,
    item_list_name: (Router.query?.itemListName as string | undefined) ?? null,
    item_list_id: (Router.query?.itemListId as string | undefined) ?? null,
    item_variant: variation
      ? variation.node.sku
      : bundle
        ? product.customBoxVariant
        : null,
    price: priceToNumber(regularPrice) * qty,
    quantity: qty,
    image: product.image?.sourceUrl ?? null,
    url: product?.link ? getRealURL(product.link) : null,
    ...(product.customBox && {
      custom_box: product.customBox,
    }),
    ...(product.customBoxVariant && {
      custom_box_variant: product.customBoxVariant,
    }),
    ...(bundle && {
      custom_box_items: bundle
        .map((item) => `${item.product.sku}/${item.quantity}`)
        .join(','),
    }),
  };

  if (product.productCategories) {
    const categories = getProductCategoriesTree(product);

    item = {
      ...item,
      ...categories,
    };
  }

  return [item, ...(bundle ? normalizeEventBundle(bundle, index) : [])];
};

const normalizeEventBundle = (items: any[], index: number | undefined) =>
  items
    .map((item) => normalizeEventItem(item.product, index, item.quantity))
    .flat();
