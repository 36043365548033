import { createContext, useReducer } from 'react';
import Router from 'next/router';
import { debounce } from 'lodash-es';

import { CheckboxCircle } from '@/icons';

import { routes } from '@/lib/routes';

import type { TypeToast } from '@/components/types';
import type { ReactNode } from 'react';

type TypeToastDispatch = {
  toast: (message: string, options: ToastOptions) => void;
  closeToast: (id: string) => void;
  deleteToast: (id: string) => void;
};

type ToastOptions = Pick<
  TypeToast,
  'Icon' | 'backgroundColor' | 'duration' | 'link'
>;

enum ToastAction {
  ADD_TOAST,
  DISMISS_TOAST,
  REMOVE_TOAST,
}

type TypeToastAction =
  | {
      type: ToastAction.ADD_TOAST;
      toast: TypeToast;
    }
  | {
      type: ToastAction.DISMISS_TOAST;
      toastId?: string;
    }
  | {
      type: ToastAction.REMOVE_TOAST;
      toastId?: string;
    };

const initialState: TypeToast[] = [];

export const ToastContext = createContext<TypeToast[]>(initialState);
export const ToastDispatchContext = createContext<TypeToastDispatch>({
  closeToast: () => null,
  deleteToast: () => null,
  toast: () => null,
});

const createToast = (message: string, options: ToastOptions): TypeToast => ({
  id: 'TOAST',
  message,
  ...(options.duration
    ? {
        duration: options.duration,
      }
    : {
        duration: 3000,
      }),
  createdAt: Date.now(),
  visible: true,
  ...(options.link
    ? {
        ...(options.link.type === 'action' && {
          link: {
            type: options.link.type,
            label: options.link.label,
            action: options.link.action,
          },
        }),
        ...(options.link.type === 'url' && {
          link: {
            type: options.link.type,
            label: options.link.label,
            url: options.link.url,
          },
        }),
      }
    : {}),
  backgroundColor: options.backgroundColor,
  ...(options.Icon
    ? {
        Icon: options.Icon,
      }
    : {}),
});

const DEFAULT_TOAST_OPTIONS = {
  duration: 3000,
  Icon: CheckboxCircle,
};

/**
 * Toast options that may be common so you don't have to write them every time.
 * Can be overwritten if need it
 * 
 * The default values are:
 *  - Duration: 3000
 *  - Icon: CheckboxCircle
 *  - BackgroundColor: "#f68217",
 *  - link: `{
        type: 'url',
        label: 'Finalizar compra',
        url: routes.cart,
      }`
 */
export const DEFAULT_TOAST_OPTIONS_INFO: ToastOptions = {
  ...DEFAULT_TOAST_OPTIONS,
  backgroundColor: '#f68217',
  link: {
    type: 'url',
    label: 'Finalizar compra',
    url: routes.cart,
  },
};

/**
 * Toast options that may be common so you don't have to write them every time.
 * Can be overwritten if need it
 * 
 * The default values are:
 *  - Duration: 3000
 *  - Icon: CheckboxCircle
 *  - BackgroundColor: "#d32929",
 *  - link: `{
        type: 'action',
        label: 'Recargar',
        action: () => Router.reload(),
      }`
 */
export const DEFAULT_TOAST_OPTIONS_WARNING: ToastOptions = {
  ...DEFAULT_TOAST_OPTIONS,
  backgroundColor: '#d32929',
  link: {
    type: 'action',
    label: 'Recargar',
    action: () => Router.reload(),
  },
};

const reducer = (toasts: TypeToast[], action: TypeToastAction): TypeToast[] => {
  switch (action.type) {
    case ToastAction.ADD_TOAST:
      return [
        ...toasts,
        {
          ...action.toast,
        },
      ];

    case ToastAction.DISMISS_TOAST:
      return toasts.map((toast) =>
        toast.id === action.toastId
          ? {
              ...toast,
              visible: false,
            }
          : toast,
      );

    case ToastAction.REMOVE_TOAST:
      return action.toastId
        ? toasts.filter((toast) => toast.id !== action.toastId)
        : [];

    default: {
      throw Error(`Tipo de action desconocida: ${action}`);
    }
  }
};

let timeOut: NodeJS.Timeout;

export const ToastProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const [toasts, dispatch] = useReducer(reducer, initialState);

  const deleteToast = (id: string) => {
    dispatch({
      type: ToastAction.REMOVE_TOAST,
      toastId: id,
    });
  };

  const closeToast = (id: string) => {
    dispatch({
      type: ToastAction.DISMISS_TOAST,
      toastId: id,
    });

    timeOut = setTimeout(() => {
      deleteToast(id);
    }, 1000);
  };

  const toastDebounceFn = debounce((toast: TypeToast) => {
    clearTimeout(timeOut);

    deleteToast(toast.id);

    setTimeout(() => {
      dispatch({
        type: ToastAction.ADD_TOAST,
        toast,
      });

      timeOut = setTimeout(() => {
        closeToast(toast.id);
      }, toast.duration);
    });
  }, 200);

  /**
   * Callback function to trigger a toast
   * @param {string} message Toast message
   * @param {ToastOptions} options Toast Options
   */
  const toast = (message: string, options: ToastOptions) => {
    const toast = createToast(message, options);

    toastDebounceFn(toast);
  };

  return (
    <ToastContext.Provider value={toasts}>
      <ToastDispatchContext.Provider
        value={{
          toast,
          closeToast,
          deleteToast,
        }}
      >
        {children}
      </ToastDispatchContext.Provider>
    </ToastContext.Provider>
  );
};
