import { gql } from '@apollo/client';

import { cartPartial } from '../partials';

export const removeCoupon = (extraData: string = '') => gql`
mutation RemoveCouponMutation($code: [String!]) {
  removeCoupons(input: {codes: $code}) {
    ${cartPartial(extraData)}
  }
}
`;
