import type { Woosb } from '@/lib/graphql/transformers/product';

export const calculateDiscount = (
  price: number,
  discount?: Woosb['discount'],
) =>
  !discount || discount.type === 'none'
    ? price
    : // IF BOTH DISCOUNTS ARE ACTIVE, WE ONLY APPLY THE AMOUNT DISCOUNT THIS IS A WOOCOMMERCE RULE
      discount.type === 'amount'
      ? price - (discount?.amount ?? 0)
      : price * (1 - (discount?.percentage ?? 0) / 100);
