import { gql } from '@apollo/client';

import { cartPartial } from '../partials/cart/Cart';

export const addToCart = (extraData: string = '') => gql`
  mutation addToCart($items: [CartItemInput]!) {
    addCartItems(input: {items: $items} ) {
      clientMutationId
      ${cartPartial(extraData)}
    }
  }
`;
