import { CompleteProduct } from './CompleteProduct';
import { PurchableBaseProducts } from './PurchableBaseProducts';

export const CartProducts = `
  ${CompleteProduct}
  ${PurchableBaseProducts}
  sellerInfo {
    slug
  }
`;
