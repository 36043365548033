import { useEffect, useMemo, useState } from 'react';
import Image from 'next/future/image';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';

import { ModalV2 as Modal } from '@/components/molecules';

import FallBackIMG from '@/assets/images/fallback-product-card.jpg';

import type { InitialPropsProvider } from '@/components/providers/initPropsProvider';

// Routes where the user can navigated without accepting the age consent.
const SAFE_ROUTES = [
  '/cesta',
  '/finalizar-compra',
  '/finalizar-compra/paso/datos',
  '/finalizar-compra/paso/direccion',
  '/finalizar-compra/paso/confirmacion',
  '/finalizar-compra/paso/gracias',
];

const COOKIE_NAME = 'ageVerification';

export const AgeGate = ({
  showAgeGate,
  logo,
}: {
  showAgeGate: boolean;
  logo: InitialPropsProvider['options']['logo'];
}) => {
  const cookies = useMemo(() => new Cookies(), []);
  const router = useRouter();
  const [modalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    const ageVerificationCookie: 'true' | 'false' | undefined =
      cookies.get(COOKIE_NAME);

    const isSafeRoute = SAFE_ROUTES.includes(router.pathname);

    if (ageVerificationCookie === 'true') setModalOpen(false);
    else if (ageVerificationCookie === undefined && !showAgeGate) {
      setModalOpen(false);
      cookies.set(COOKIE_NAME, 'false', { path: '/' });
    } else if (
      ageVerificationCookie === 'false' &&
      (isSafeRoute || !showAgeGate)
    ) {
      setModalOpen(false);
    } else {
      setModalOpen(true);
    }
  }, [cookies, showAgeGate, router.pathname]);

  return (
    <Modal
      open={modalOpen}
      setOpen={setModalOpen}
      backdrop={{ color: '#262626', opacity: 0.8 }}
      closeOnBackdropClick={false}
      maxWidth={529}
    >
      <div className="flex flex-col items-center justify-center bg-primary-50 py-12">
        <Image
          src={logo?.src || FallBackIMG.src}
          alt={logo?.alt || 'Logo Bigcrafters'}
          height={64}
          width={240}
          className="object-contain object-center"
          priority
          draggable={false}
        />

        <p className="u-subtitle u-subtitle--l my-6 text-center text-secondary-500">
          DISCULPA, PERO TENEMOS <br />
          QUE PREGUNTÁRTELO 😊
        </p>

        <p className="u-headline u-headline@mobile--h1 text-center leading-none md:text-[44px]">
          ¿ERES MAYOR DE EDAD?
        </p>

        <div className="relative my-8 flex text-black">
          <button
            className="u-subtitle u-subtitle--s mx-4 h-16 w-16 rounded-full border border-black sm:h-20 sm:w-20"
            onClick={() => {
              cookies.set('ageVerification', true, { path: '/' });
              setModalOpen(false);
            }}
          >
            SÍ
          </button>

          <a
            className="u-subtitle u-subtitle--s mx-4 inline-flex h-16 w-16 items-center justify-center rounded-full border border-black sm:h-20 sm:w-20"
            rel="noopener noreferrer"
            href="https://www.google.es"
          >
            NO
          </a>
        </div>
      </div>
    </Modal>
  );
};
