import { cartContents } from '@/lib/graphql/partials/cart/CartContents';
import { appliedCoupons } from '@/lib/graphql/partials/cart/Coupons';
import { shippingMethods } from '@/lib/graphql/partials/cart/shippingMethods';

export const cartPartial = (extraData = '') => `
  cart {
    ${appliedCoupons}
    ${shippingMethods}
    shippingDates {
      estimatedDate
      name
      slug
      minFreeAmount
      shippingAmount
    }
    availablePaymentMethods {
      key
    }
    chosenShippingMethods
    contentsTax
    contentsTotal
    discountTax
    discountTotal
    feeTax
    displayPricesIncludeTax
    feeTotal
    shippingTax
    shippingTotal
    subtotal
    subtotalTax
    total
    totalTax
    cartSession
    ${cartContents}
    ${extraData}
  }
`;
