import { Component } from 'react';

import { sendLogs } from '@/lib/graphql/api';
import { debug, isProduction } from '@/lib/utils';

import type { ErrorInfo, JSXElementConstructor, ReactNode } from 'react';
import type { TypeErrorProps } from './error/types';

interface ErrorBoundaryProps {
  children: ReactNode;
  fallback: JSXElementConstructor<TypeErrorProps>;
  type: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    debug('Uncaught error:', error, errorInfo);

    const errorJson = {
      name: error.name,
      message: error.message,
      stack: error.stack,
      cause: error.cause,
      componentStack: errorInfo.componentStack,
    };

    if (isProduction())
      sendLogs({ type: 'error-boundary', message: errorJson });
  }

  render() {
    if (this.state.hasError) {
      return <this.props.fallback error={this.props.type} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
