import type {
  TypeShippingClassesGQL,
  TypeShippingDateGQL,
} from './order/types';

export type TypeShippingEstimateDate = {
  name: string;
  date: string;
  id: string;
  minFreeAmount: number;
  price: number;
};

/**
 * Get the shipping date from the shipping type selected with custom delivery date override
 *
 * @param {any[]} shippingDates - Array of shipping dates
 * @param {any} selectedShippingType - Selected shipping type
 * @param {string} [customDeliveryDate] - Override the shipping date with a custom date
 * @returns {TypeShippingEstimateDate} Object with the shipping date
 */
export const shippingEstimateDateTransformer = (
  shippingDates: TypeShippingDateGQL[],
  selectedShippingType: TypeShippingClassesGQL,
  customDeliveryDate?: string,
): TypeShippingEstimateDate => {
  const shippingInfo = shippingDates.find(
    (shippingDate) => shippingDate.name === selectedShippingType.name,
  );
  const getCustomDeliveryDate = (deliveryDate: string) => {
    const customDate = new Date(deliveryDate).toLocaleDateString('es-es', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    return `ENVIO: ${customDate}`;
  };

  return {
    name: shippingInfo?.name ?? '',
    date: customDeliveryDate
      ? getCustomDeliveryDate(customDeliveryDate)
      : (shippingInfo?.estimatedDate ?? ''),
    id: shippingInfo?.slug ?? '',
    minFreeAmount: shippingInfo?.minFreeAmount ?? 0,
    price: shippingInfo?.shippingAmount ?? 0,
  };
};
