export const priceToNumber = (number: string | number): number =>
  typeof number === 'number'
    ? parseFloat(number.toFixed(2))
    : parseFloat(
        parseFloat(
          number?.replace(/€/g, '').replace('.', '').replace(',', '.'),
        ).toFixed(2),
      );

export const toCurrency = (toConvert: number | string) =>
  new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(priceToNumber(toConvert));

const operateCurrency =
  (...args: (string | number)[]) =>
  (operator: (num1: number, num2: number) => number) =>
    toCurrency(
      [...args].reduce((a, b) => operator(priceToNumber(a), priceToNumber(b))),
    );

/**
 * Sums a list of prices or numbers
 *
 * @param args Prices or numbers to sum
 * @returns The result of the sum
 */
export const SumCurrency = (...args: (string | number)[]) =>
  operateCurrency(...args)((num1, num2) => num1 + num2);

/**
 * Substracts a list of prices or numbers
 *
 * @param args Prices or numbers to substract
 * @returns The result of the substraction
 */
export const SubCurrency = (...args: (string | number)[]) =>
  operateCurrency(...args)((num1, num2) => num1 - num2);

export const getPercentageDiscount = (
  regularPrice: number,
  salePrice: number,
) => Math.round(100 - (salePrice / regularPrice) * 100);

export const getDiscountAmount = (
  regularPrice: number,
  salePrice: number,
): number => regularPrice - salePrice;

export const isFreeShipping = (shippingCost: string | number) =>
  typeof shippingCost === 'string'
    ? shippingCost === '0,00 €'
    : shippingCost === 0;
export const classShipping = (shippingCost: string | number) =>
  isFreeShipping(shippingCost) ? 'text-status-success' : 'text-typo-secondary';
