import { createContext } from 'react';

import { config } from '@/config';

import type {
  bcOptionsServerTransformer,
  headerServerTransformer,
  menuServerTransformer,
} from '@/lib/graphql/transformers';

export type InitialPropsProvider = {
  menus: {
    primaryMenu: ReturnType<typeof headerServerTransformer>;
    legal: ReturnType<typeof menuServerTransformer>;
    recommended: ReturnType<typeof menuServerTransformer>;
    about: ReturnType<typeof menuServerTransformer>;
    support: ReturnType<typeof menuServerTransformer>;
    extra: ReturnType<typeof menuServerTransformer>;
  };
  options: Awaited<ReturnType<typeof bcOptionsServerTransformer>>;
};

export const InitialPropsProviderDefault: InitialPropsProvider = {
  menus: {
    primaryMenu: [],
    legal: undefined,
    recommended: undefined,
    about: undefined,
    support: undefined,
    extra: undefined,
  },
  options: {
    numMaxProduct: config.maxProductsSelectProductPage,
    weightMaxCart: config.weightMaxCart,
  },
};

export const InitPropsProvider = createContext(
  InitialPropsProviderDefault, // valor por defecto
);
