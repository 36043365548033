import type { TypeOrderTransformer } from '../graphql/transformers';
import type { TypeStatusOrderGQL } from '../graphql/transformers/order/types';

export const WordpressStatusOrder: {
  [key in TypeStatusOrderGQL]: string;
} = {
  PEDIDO_RECIBIDO: 'PEDIDO_RECIBIDO',
  REFUNDED: 'REFUNDED',
  ON_HOLD: 'ON_HOLD',
  PEDIDO_ACEPTACION: 'PEDIDO_ACEPTACION',
  PROCESSING: 'PROCESSING',
  PEDIDO_ACEPTADO: 'PEDIDO_ACEPTADO',
  PEDIDO_ENVIADO: 'PEDIDO_ENVIADO',
  PENDING: 'PENDING',
  PEDIDO_RECHAZADO: 'PEDIDO_RECHAZADO',
  CANCELLED: 'CANCELLED',
  PEDIDO_CERRADO: 'PEDIDO_CERRADO',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  PEN_CANC: 'PEN_CANC',
};

export const WORDPRESS_OPEN_STATUS_ORDER = [
  WordpressStatusOrder.PEDIDO_RECIBIDO,
  WordpressStatusOrder.REFUNDED,
  WordpressStatusOrder.ON_HOLD,
  WordpressStatusOrder.PEDIDO_ACEPTACION,
  WordpressStatusOrder.PROCESSING,
  WordpressStatusOrder.PEDIDO_ACEPTADO,
  WordpressStatusOrder.PEDIDO_ENVIADO,
  WordpressStatusOrder.PENDING,
  WordpressStatusOrder.PEDIDO_RECHAZADO,
  WordpressStatusOrder.FAILED,
  WordpressStatusOrder.PEN_CANC,
];

export const WORDPRESS_CLOSED_STATUS_ORDER = [
  WordpressStatusOrder.COMPLETED,
  WordpressStatusOrder.PEDIDO_CERRADO,
  WordpressStatusOrder.CANCELLED,
];

export const enum FrontStatusOrder {
  DELIVERED = 'Entregado',
  REFUNDED = 'Reembolsado',
  ON_HOLD = 'En Espera',
  PROCESSING = 'Procesando',
  ACCEPTED_ORDER = 'Pedido aceptado',
  ONSHIPPING = 'En envío',
  PENDING = 'Pendiente de pago',
  REFUSED = 'Rechazado por el vendedor',
  CANCELED = 'Cancelado',
  COMPLETED = 'Completado',
  FAILED = 'Fallido',
}

export const FRONT_OPEN_STATUS_ORDER = [
  FrontStatusOrder.DELIVERED,
  FrontStatusOrder.ON_HOLD,
  FrontStatusOrder.PROCESSING,
  FrontStatusOrder.ACCEPTED_ORDER,
  FrontStatusOrder.ONSHIPPING,
  FrontStatusOrder.PENDING,
  FrontStatusOrder.REFUSED,
  FrontStatusOrder.FAILED,
];

export const FRONT_CLOSED_STATUS_ORDER = [
  FrontStatusOrder.COMPLETED,
  FrontStatusOrder.CANCELED,
  FrontStatusOrder.REFUNDED,
];

export const translateStatus = (status: string): FrontStatusOrder | string => {
  switch (status?.toUpperCase()) {
    case WordpressStatusOrder.PEDIDO_RECIBIDO:
      return FrontStatusOrder.DELIVERED;
    case WordpressStatusOrder.REFUNDED:
      return FrontStatusOrder.REFUNDED;
    case WordpressStatusOrder.ON_HOLD:
      return FrontStatusOrder.ON_HOLD;
    case WordpressStatusOrder.PEDIDO_ACEPTACION:
    case WordpressStatusOrder.PEN_CANC:
    case WordpressStatusOrder.PROCESSING:
      return FrontStatusOrder.PROCESSING;
    case WordpressStatusOrder.PEDIDO_ACEPTADO:
      return FrontStatusOrder.ACCEPTED_ORDER;
    case WordpressStatusOrder.PEDIDO_ENVIADO:
      return FrontStatusOrder.ONSHIPPING;
    case 'REDSYS-PBANKT':
    case WordpressStatusOrder.PENDING:
      return FrontStatusOrder.PENDING;
    case WordpressStatusOrder.PEDIDO_RECHAZADO:
      return FrontStatusOrder.REFUSED;
    case WordpressStatusOrder.CANCELLED:
      return FrontStatusOrder.CANCELED;
    case WordpressStatusOrder.PEDIDO_CERRADO:
    case WordpressStatusOrder.COMPLETED:
      return FrontStatusOrder.COMPLETED;
    case WordpressStatusOrder.FAILED:
      return FrontStatusOrder.FAILED;
    case 'ACTIVE':
      return 'Activo';
    default:
      return status;
  }
};

export const getOrderStateColor = (status: FrontStatusOrder | string) => {
  switch (status) {
    case FrontStatusOrder.DELIVERED:
    case FrontStatusOrder.REFUNDED:
      return 'text-status-success';
    case FrontStatusOrder.ON_HOLD:
    case FrontStatusOrder.PENDING:
    case FrontStatusOrder.REFUSED:
    case FrontStatusOrder.CANCELED:
    case FrontStatusOrder.FAILED:
      return 'text-status-error';
    case FrontStatusOrder.PROCESSING:
    case FrontStatusOrder.ACCEPTED_ORDER:
    case FrontStatusOrder.ONSHIPPING:
      return 'text-status-progress';
    default:
      return '';
  }
};

export const isClosedStatus = (status: FrontStatusOrder | string) =>
  FRONT_CLOSED_STATUS_ORDER.some((closedStatus) => closedStatus === status);

export const isBadOrder = (status: FrontStatusOrder | string) =>
  status === FrontStatusOrder.ON_HOLD ||
  status === FrontStatusOrder.PENDING ||
  status === FrontStatusOrder.REFUSED ||
  status === FrontStatusOrder.CANCELED ||
  status === FrontStatusOrder.FAILED;

export const isFinishedOrder = (status: FrontStatusOrder | string) =>
  status === FrontStatusOrder.COMPLETED ||
  status === FrontStatusOrder.REFUNDED ||
  status === FrontStatusOrder.DELIVERED ||
  status === FrontStatusOrder.REFUSED ||
  status === FrontStatusOrder.CANCELED;

export const showInvoice = (status: FrontStatusOrder | string) =>
  status === FrontStatusOrder.DELIVERED ||
  status === FrontStatusOrder.ACCEPTED_ORDER ||
  status === FrontStatusOrder.PROCESSING ||
  status === FrontStatusOrder.ONSHIPPING ||
  status === FrontStatusOrder.COMPLETED;

export const OPERATOR = 'Bigcrafters';
export const isOperator = (operator: string) =>
  operator.toLocaleLowerCase() === OPERATOR.toLocaleLowerCase();

export const getProductsFromSellers = (
  sellers: TypeOrderTransformer['sellers'],
) =>
  sellers
    .map((seller) =>
      [...seller.orders.values()].map((order) =>
        [...order.offers.values()].map((offer) => ({
          quantity: offer.quantity,
          productId: offer.product.databaseId,
        })),
      ),
    )
    .flat(2);
