import {
  FrontStatusOrder,
  kebabCase,
  OPERATOR,
  translateStatus,
} from '@/lib/utils';

import type { TypeSuborderGQL } from './order/types';
import type { TypeShippingEstimateDate } from './shipping-estimate-date';

export const getOrderID = (shippingEstimateDate: TypeShippingEstimateDate) =>
  kebabCase(`${shippingEstimateDate.id}${shippingEstimateDate.date}`);

/**
 * sellerName can be ""
 */
export const getSellerName = (sellerName: string) =>
  sellerName ? sellerName : OPERATOR;

/**
 * Only show the refund if the order is not refunded, canceled or refused
 *
 * @param status The status of the order
 * @returns A boolean indicating if the order should show the refund
 */
export const shouldShowRefund = (subOrder: TypeSuborderGQL) =>
  subOrder?.totalRefund > 0 &&
  translateStatus(subOrder?.status) !== FrontStatusOrder.REFUNDED &&
  translateStatus(subOrder?.status) !== FrontStatusOrder.CANCELED &&
  translateStatus(subOrder?.status) !== FrontStatusOrder.REFUSED;

export const hasAlert = (subOrder: TypeSuborderGQL) =>
  translateStatus(subOrder?.status) === FrontStatusOrder.PENDING ||
  (subOrder?.hasIncident ?? false) ||
  shouldShowRefund(subOrder);
