import { getSession, signOut } from 'next-auth/react';
import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { debug, isProduction } from '@/lib/utils';
import { config } from '@/config';

import type { SourceLocation } from 'graphql';

type TypeResultError = {
  message: string;
  extensions: {
    category: string;
  };
};

export const generateError = (
  message: string,
  locations: readonly SourceLocation[] | undefined,
  path: readonly (string | number)[] | undefined,
) => {
  const printableLocations = locations?.map(
    (location: any) => `{ line: ${location.line}, column: ${location.column} }`,
  );

  return `[GraphQL error]: Message: ${message}, Locations: ${printableLocations}, Path: ${path}`;
};

const httpLink = new HttpLink({
  uri: `${process.env.NEXT_PUBLIC_FRONT_URL}${process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT}`,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(
      async ({
        message,
        locations,
        path,
        // In debug mode, we recieve the debugMessage
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        debugMessage,
      }) => {
        if (message?.includes('jwt') || message?.includes('JWT')) {
          await signOut();
        }

        if (debugMessage) debug(generateError(message, locations, path));
      },
    );

  if (networkError) {
    debug(`[Network error]: ${networkError.message}`);
    debug(
      // networkError has a result property, even if the type is wrong
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      `  [Result]: ${networkError.result?.errors?.map(
        (e: TypeResultError) => e?.message,
      )}`,
    );

    if (isProduction())
      networkError.message =
        'Oops! Lo sentimos, se ha producido un error en la red';
  }
});

/**
 * Middleware operation
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 */
export const middleware = new ApolloLink((operation, forward) => {
  /**
   * If session data exist in local storage, set value as session header.
   */

  if (typeof window === 'undefined') {
    return forward(operation);
  }
  const session = localStorage.getItem(config.session.woocommerce);
  if (session) {
    operation.setContext(() => ({
      headers: {
        'woocommerce-session': `Session ${session}`,
      },
    }));
  }

  return forward(operation);
});

const authLink = setContext(async (_, { headers }) => {
  const session = await getSession();
  return {
    headers: {
      ...headers,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      authorization: session?.accessToken
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          `Bearer ${session?.accessToken}`
        : '',
    },
  };
});

/**
 * Afterware operation
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
export const afterware = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    /**
     * Check for session header and update session in local storage accordingly.
     */

    if (typeof window === 'undefined') {
      return response;
    }

    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    const session = headers.get('woocommerce-session');

    if (
      session &&
      localStorage.getItem(config.session.woocommerce) !== session
    ) {
      localStorage.setItem(
        config.session.woocommerce,
        headers.get('woocommerce-session'),
      );
    }

    return response;
  }),
);

const defaultOptions: any = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const APClient = new ApolloClient({
  link: from([errorLink, middleware, authLink, afterware, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export default APClient;
