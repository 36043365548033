import {
  FrontStatusOrder,
  isOperator,
  priceToNumber,
  SubCurrency,
  SumCurrency,
  toCurrency,
  translateStatus,
} from '@/lib/utils';
import { customTagTransformer } from '../custom-tag';
import { shippingEstimateDateTransformer } from '../shipping-estimate-date';
import {
  getOrderID,
  getSellerName,
  hasAlert,
  shouldShowRefund,
} from '../utils';

import type { TypeMetaData } from '@/types';
import type { TypeBundleItemsCart } from '../../types/cart';
import type {
  TypeBillingGQL,
  TypeCouponGQL,
  TypeImageGQL,
  TypeLineItemGQL,
  TypeOrderGQL,
  TypeProductNodeGQL,
  TypeShippingGQL,
  TypeSuborderGQL,
  TypeVariationNodeGQL,
} from './types';

type TypeOffer = {
  id: string;
  quantity: number;
  remainingRefundQuantity: number | null;
  total: string;
  remainingRefund: string | null;
  canOpenIncidence: boolean;
  product: {
    name: string;
    image: TypeImageGQL;
    link: string;
    onSale: boolean;
    sku: string;
    databaseId: number;
  };
  bundleItems?: TypeBundleItemsCart;
  key: string;
};

type TypeOrder = {
  id: string | number;
  shippingEstimateDate: any;
  total: string;
  offers: TypeOffer[];
  tracking: string | null;
  canCancel: boolean;
  isOperator: boolean;
};

type TypeSeller = {
  id: string;
  name: string;
  shippingTotal: number;
  orders: Map<string, TypeOrder> | TypeOrder[];
  numItems: number;
  status: string;
  orderNumber: string;
  showAlert: boolean;
};

export type TypeOrderTransformer = {
  sellers: TypeSeller[];
  allSellers: string;
  status: FrontStatusOrder | string;
  lineItems: {
    node: TypeLineItemGQL;
  }[];
  numItems: number;
  hasIncident: boolean;
  appliedCoupons: {
    discountTax: number;
    discountAmount: number;
    code: string;
    discount: string;
  }[];
  refundedItems: TypeOffer[];
  date: Date;
  paymentMethodTitle: string;
  totalRefund: number;
  shippingRefund: number;
  parent: {
    id: string;
  } | null;
  paymentMethod: string;
  remainingRefund: number;
  total: string;
  cartTax: string;
  discountTax: string;
  subtotal: string;
  shippingTotal: string;
  shippingTax: string;
  id: string;
  orderNumber: string;
  shipping: TypeShippingGQL;
  billing: TypeBillingGQL;
  customer?: {
    id: number | string;
  };
  databaseId?: number;
  discountTotal?: string;
  totalTax?: string;
};

export const findIsChildOfBundleInOrder = (data: TypeMetaData) =>
  data.key === 'woosb_parent_key';

const getOfferRefund = (offer: TypeOffer) => {
  const offerRefund = structuredClone(offer);
  offerRefund.quantity =
    offerRefund.quantity - (offerRefund.remainingRefundQuantity ?? 0);
  offerRefund.total = SubCurrency(
    offerRefund.total,
    offerRefund.remainingRefund ?? '0',
  );

  return offerRefund;
};

const getOffer = (
  productVariation: TypeVariationNodeGQL,
  product: TypeProductNodeGQL,
  line: TypeLineItemGQL,
  subOrder: TypeSuborderGQL,
): TypeOffer => {
  const total = parseFloat(line.total ?? 0);
  const totalTax = parseFloat(line.totalTax ?? 0);
  const totalRefund = line?.totalRefund;
  const id =
    line?.miraklitemid ?? productVariation?.databaseId ?? product.databaseId;

  return {
    id,
    quantity: line.quantity,
    remainingRefundQuantity:
      shouldShowRefund(subOrder) && totalRefund > 0
        ? line.quantity - Math.abs(line.quantityRefund)
        : null,
    total: toCurrency(total + totalTax),
    remainingRefund:
      shouldShowRefund(subOrder) && totalRefund > 0
        ? toCurrency(total + totalTax - totalRefund)
        : null,
    canOpenIncidence: line?.canOpenIncidence ?? false,
    product: {
      name: productVariation?.name ?? product.name,
      image: productVariation?.featuredImage?.node ?? product.image,
      link: product.link,
      onSale: product.onSale,
      sku: productVariation?.sku ?? product.sku,
      databaseId: productVariation?.databaseId ?? product.databaseId,
    },
    ...(product.autoCalculatePrice && { bundleItems: [] }),
    key:
      line.metaData?.find((data) => data.key === 'cart_item_key')?.value ||
      id.toString(),
  };
};

const getOrder = (
  productVariation: TypeVariationNodeGQL,
  product: TypeProductNodeGQL,
  line: TypeLineItemGQL,
  shippingEstimateDate: any,
  subOrder: TypeSuborderGQL,
): TypeOrder => {
  const translatedStatus = translateStatus(subOrder.status);
  const offer = getOffer(productVariation, product, line, subOrder);
  const sellerName = getSellerName(subOrder.sellerName);

  return {
    id: subOrder?.miraklorderid || subOrder?.databaseId,
    shippingEstimateDate,
    total: '',
    offers: [offer],
    tracking: subOrder?.trackingUrl ?? null,
    canCancel: isOperator(sellerName)
      ? translatedStatus !== FrontStatusOrder.CANCELED &&
        translatedStatus !== FrontStatusOrder.COMPLETED &&
        translatedStatus !== FrontStatusOrder.DELIVERED &&
        translatedStatus !== FrontStatusOrder.ONSHIPPING
      : (subOrder?.canCancel ?? false),
    isOperator: isOperator(sellerName),
  };
};

const getOrders = (subOrder: TypeSuborderGQL) => {
  const orders = new Map<string, TypeOrder>();
  const lineItemsSubOrder: {
    node: TypeLineItemGQL;
  }[] = [];
  const refundedItemsOrder: TypeOffer[] = [];
  const edges = subOrder.lineItems.edges;

  for (const line of edges) {
    const metaData = line.node.metaData;
    const product = customTagTransformer(
      line.node.product.node,
      metaData,
    ) as TypeProductNodeGQL;
    const productVariation = line.node?.variation?.node ?? null;

    const selectedShippingType = product?.shippingClasses?.edges?.[0]?.node;

    if (!selectedShippingType) continue;

    const shippingEstimateDate = shippingEstimateDateTransformer(
      subOrder.shippingDates,
      selectedShippingType,
    );
    const orderID = getOrderID(shippingEstimateDate);
    const keyBundle = metaData?.find(findIsChildOfBundleInOrder);

    if (!Boolean(keyBundle)) {
      if (orders.has(orderID)) {
        const order = orders.get(orderID);
        const offer = getOffer(productVariation, product, line.node, subOrder);

        if (
          offer.remainingRefundQuantity !== null &&
          offer.remainingRefundQuantity >= 0
        )
          refundedItemsOrder.push(getOfferRefund(offer));

        if (offer.quantity > 0 && order) order.offers.push(offer);
      } else {
        const order = getOrder(
          productVariation,
          product,
          line.node,
          shippingEstimateDate,
          subOrder,
        );
        const offer = order.offers[0];

        if (
          offer &&
          offer.remainingRefundQuantity !== null &&
          offer.remainingRefundQuantity >= 0
        )
          refundedItemsOrder.push(getOfferRefund(offer));

        if (offer && offer.remainingRefundQuantity === 0) order.offers.pop();

        orders.set(orderID, order);
      }

      lineItemsSubOrder.push(line);
    } else if (keyBundle) {
      const parentItem = edges.find((edge) =>
        edge.node.metaData?.find((d) => d.value === keyBundle.value),
      );

      if (
        !parentItem ||
        !parentItem?.node?.product?.node?.shippingClasses?.edges?.[0]?.node
      )
        continue;

      const parentOrderID = getOrderID(
        shippingEstimateDateTransformer(
          subOrder.shippingDates,
          parentItem.node.product.node.shippingClasses.edges[0].node,
        ),
      );

      const order = orders.get(parentOrderID);

      if (!order) continue;

      const offers = order.offers;
      const bundle = offers.find((offer) => offer.key === keyBundle.value);

      if (!bundle) continue;

      if (!Array.isArray(bundle.bundleItems)) continue;

      const offer = getOffer(productVariation, product, line.node, subOrder);

      bundle.bundleItems.push({
        name: offer.product.name,
        quantity:
          bundle.quantity > 1
            ? offer.quantity / bundle.quantity
            : offer.quantity,
        price:
          bundle.quantity > 1
            ? toCurrency(
                priceToNumber(
                  SubCurrency(offer.total, offer.remainingRefund || '0'),
                ) / bundle.quantity,
              )
            : offer.total,
      });

      if (
        offer.remainingRefundQuantity !== null &&
        offer.remainingRefundQuantity >= 0
      )
        refundedItemsOrder.push(getOfferRefund(offer));

      bundle.total = SumCurrency(bundle.total, offer.total);

      lineItemsSubOrder.push(line);
    }
  }

  return { orders, lineItemsSubOrder, refundedItemsOrder };
};

export const orderTransformer = (
  order: TypeOrderGQL | undefined | null,
): TypeOrderTransformer | null => {
  if (!order) return null;

  const sellers = new Map<string, TypeSeller>();
  const { subpedidos, status, couponLines, ...restOfOrder } =
    structuredClone(order);
  const lineItems: {
    node: TypeLineItemGQL;
  }[] = [];
  const allSellers: string[] = [];
  const refundedItems: TypeOffer[] = [];
  let numItems = 0;
  let hasIncident = false;

  subpedidos?.forEach((subOrder: TypeSuborderGQL) => {
    const sellerName = getSellerName(subOrder?.sellerName);
    const sellerID = sellerName;
    const numItemsLine = subOrder.lineItems.edges.reduce(
      (
        acc: number,
        line: {
          node: TypeLineItemGQL;
        },
      ) => acc + line.node.quantity,
      0,
    );

    numItems += numItemsLine;

    if (
      translateStatus(subOrder.status) === FrontStatusOrder.COMPLETED &&
      subOrder.totalRefund === priceToNumber(subOrder.total)
    ) {
      subOrder.status = 'REFUNDED';
    }

    if (sellers.has(sellerID)) {
      const seller = sellers.get(sellerID);
      const { orders, lineItemsSubOrder, refundedItemsOrder } =
        getOrders(subOrder);

      // TODO: Cuando de un mismo seller vienen dos pedidos con el mismo id, se sobreescriben
      if (seller && seller.orders instanceof Map) {
        seller.orders = new Map([...seller.orders, ...orders]);
      }
      if (seller) {
        seller.showAlert = seller.showAlert || hasAlert(subOrder);
      }
      lineItems.push(...lineItemsSubOrder);
      refundedItems.push(...refundedItemsOrder);
    } else {
      const { orders, lineItemsSubOrder, refundedItemsOrder } =
        getOrders(subOrder);
      const seller = {
        id: sellerID,
        name: sellerName,
        shippingTotal:
          priceToNumber(subOrder.shippingTotal) +
          priceToNumber(subOrder.shippingTax),
        orders: orders,
        numItems: numItemsLine,
        status: translateStatus(subOrder?.status),
        orderNumber: subOrder?.orderNumber,
        showAlert: hasAlert(subOrder),
      };

      lineItems.push(...lineItemsSubOrder);
      refundedItems.push(...refundedItemsOrder);
      sellers.set(sellerID, seller);
      allSellers.push(sellerName);
    }

    hasIncident = hasIncident || (subOrder?.hasIncident ?? false);
  });

  const sellersArray = Array.from(sellers.values()).map((seller) => ({
    ...seller,
    orders: Array.from(seller.orders.values()),
  }));

  // fill analytics data of bundles
  lineItems.forEach((line) => {
    const metaData = line.node.metaData;
    const bundleKey = metaData?.find(
      (data) => data.key === 'cart_item_key',
    )?.value;

    const bundleMeta = metaData?.find(
      (data) => data.key === 'woosb_parent_key',
    )?.value;

    if (bundleKey && bundleMeta) {
      // Reset prices to 0 because we will calculate
      line.node.product.node.regularPrice = '0';
      line.node.product.node.salePrice = '0';

      lineItems.forEach((subLine) => {
        const metaData = subLine.node.metaData;
        const isChildOfBundle = metaData?.some(
          (data) => data.value === bundleKey && data.key === 'woosb_parent_key',
        );

        if (isChildOfBundle) {
          const newTotalTax =
            parseFloat(line.node.totalTax ?? '0') +
            parseFloat(subLine.node.totalTax);
          const newTotal =
            parseFloat(line.node.total ?? '0') + parseFloat(subLine.node.total);

          line.node.product.node.regularPrice = SumCurrency(
            line.node.product.node.regularPrice,
            subLine.node.product.node.regularPrice,
          );
          line.node.product.node.salePrice = toCurrency(newTotal + newTotalTax);
          line.node.totalTax = newTotalTax.toString();
          line.node.total = newTotal.toString();
        }
      });
    }

    return line;
  });

  return {
    sellers: sellersArray,
    status: translateStatus(status),
    allSellers: new Intl.ListFormat('es').format(allSellers),
    lineItems,
    numItems,
    hasIncident,
    // Simulate the same structure as the cart couponLines
    appliedCoupons: couponLines.edges.map((edge: { node: TypeCouponGQL }) => ({
      ...edge.node,
      discountTax: parseFloat(edge.node.discountTax ?? 0),
      discountAmount: parseFloat(edge.node.discount ?? 0),
    })),
    refundedItems,
    ...restOfOrder,
  };
};
